@use "./common/css/base" as base;

.app-container {
  display: flex;
  flex: auto;
  width: 100vw;
  height: 100vh;
  background-color: #4e4d4d;
  /* background: url("../public/images/main_background.svg") no-repeat; */
  background-size: cover;
  justify-content: center;
}

.header-holder {
  display: flex;
  flex: auto;
  max-height: var(--custom-page-header-height);
  // padding: var(--custom-page-header-padding) 0;
  padding-top: 2px;
  padding-bottom: 5px;
  width: 100%;
  overflow: hidden;
}

.content-holder {
  display: flex;
  flex: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.section-holder {
  display: flex;
  flex-direction: column;
  padding: 9.5px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  // padding-top: 5.5px;
}

.file-uploader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999; /* Ensure it's above AG-Grid */
  pointer-events: none; /* Disable mouse events for the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}