@use '../../common/css/base' as base;

.drag-text {
  display: flex;
  width: 228.639px;
  height: 12.33px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--AP-white, #fff);
  font-family: Poppins;
  font-size: 17.615px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.661px;
}

.iOBEfB > input{
  width: 100px !important;
}

.user-profile-image {
  height: 37px;
  &:hover {
    // transform: scale(1.05);
  }
}
.user-profile-menu {
  position: absolute;
  right: 15px;
  top: 45px;
  // background-color: white;
  padding: 7px 10px;
  z-index: 100000;
  div:hover {
    // background-color: #fad47f;
    cursor: pointer;
  }
}

.header-logo {
  display: flex;
  align-items: center;
  img {
    width: 10rem;
  }
}

.header-icons, .header-settings-icon {
  height: 35px;
  margin-left: 0.5rem;
  img {
    height: inherit;
    &:hover {
      // transform: scale(1.05);
    }
  }
}

.header-settings-icon {
  margin-right: 0.5rem;
  height: 30px;
}

.header-upload-continer-text {
  font-family: base.$font;
  font-style: normal;
  color: white;
  gap: 8.19px;
  font-size: var(--custom-user-details-fontSize);
  font-weight: 600;
  line-height: 17.545px;
  margin-right: 0.5rem;
}

.image-container {
  display: flex;
  padding-bottom: 3px;
  align-items: center;
  // margin-right: 3px;
}

:root {
  --custom-CB-fontSize: 11.743px;
  --custom-upload-btn-fontSize: 10.966px;
  --custom-user-details-fontSize: 12.532px;
}

.d-actions-container {
  gap: 21.34px;
}
.user-container {
  gap: 6.25px;
}
.user-details-text-size {
  font-size: var(--custom-user-details-fontSize);
}

.actions-container {
  display: flex;
  // width: calc(100% / 3);
  align-items: center;
  justify-content: end;
}

.action-buttons {
  font-family: base.$font;
  font-style: normal;
  font-weight: 600;
  color: base.$ap-white;
}

.user-details {
  font-family: base.$font;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-align: right;
  color: #ffffff;
}

input[type='file']::file-selector-button {
  background: #404040;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
}

.uploader-area {
  display: flex;
  width: calc(100% / 3);
}
