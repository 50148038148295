.tab-filter-container {
  display: flex;
  flex-direction: column;
  background-color: #4e4d4d;
  padding: 1rem 1.15rem;
  padding-left: 1.5rem;
  color: white;
  font-size: 0.75rem;
  width: 100%;
  height: 100%;
  box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.5);
  input::selection {
    background-color: #ffb000 !important;
    color: #4b4b4b;
  }
}

.tf-header {
  display: flex;
  justify-content: space-between;
  label {
    color: var(--AP-yellow, #ffb000);
    font-weight: 600;
    font-size: 1rem;
  }
  .tf-header-text {
    font-size: 1rem;
  }
  .tf-tab-name-orange {
    color: #ffb000;
    font-weight: 600;
    margin-right: 0.25rem;
  }
  .tf-header-input {
    width: 10rem;
    display: flex;
    font-size: 1rem;
    label:before {
      margin-top: 3px;
    }
    label {
      font-weight: 500;
    }
  }
}
.tfb-nav {
  border-right: 1px solid #fff;
  padding-right: 1rem;
}

.nav-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-link-clear-container {
  padding: 5px;
  cursor: pointer;
  margin-left: 7px;
}

.tfn-nav-link-box {
  color: var(--AP-yellow, #ffb000);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;

  .tfn-nav-link {
    padding: 0.25rem;
    cursor: pointer;
    margin-bottom: 0.25rem;
    min-width: 6rem;
    &:hover {
      background-color: #585858;
    }
  }
  .tfn-nav-link-active {
    background: var(--AP-medium-dark-gray, #777);
    font-weight: 600;
  }
  .tfn-nav-link-white {
    color: white;
  }

  .tfn-nav-link-separator {
    border-bottom: 1px solid #ffb000;
  }
}
.tfte-group-exact-phrases {
  .tfb-content-top-nav {
    padding-bottom: 0rem;
    justify-content: space-between;
  }
}

.tfb-content-top-nav {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  justify-content: center;

  .tfb-btn {
    // width: 3.5rem;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    cursor: pointer;
    padding: 5px 4px;
  }
  .active {
    background-color: #ffb000;
    color: #4b4b4b;
  }
}

.tf-body {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  height: calc(100% - 6rem);
  .tfb-nav {
    width: 7.5rem;
    display: flex;
    margin-top: 2rem;
  }
  .tf-tenant-entity {
    height: inherit;
    overflow-y: auto;
  }

  .tfb-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
    padding-right: 0.5rem;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
}
.tf-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.15rem;
  padding-right: 1.15rem;
  .tf-button-yellow {
    background-color: #ffb000;
  }
  .tf-button {
    width: auto;
    font-size: 0.75rem;
    height: var(--custom-close-height);
    padding: 5.872px 17.615px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 3.523px;
    font-family: Poppins;
    font-weight: 600;
  }
  .tf-buttons-holder {
    display: flex;
    gap: 1em;
  }
  .tf-footer-error {
    color: #ff3030;
    font-weight: 400;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
  }
}

.tfte-input {
  color: var(--AP-dark-gray, #4b4b4b);
  font-family: Poppins;
  font-weight: 400;
  outline: none;
}