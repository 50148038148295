@use '../../../common/css/base' as base;
:root {
  --custom-filter-header-text-font-size: 11px;
}
.filter-div {
  display: flex;
  flex: 1;
  gap: 10px;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  &:hover {
    .menu-status-text {
      color: base.$ap-black !important;
    }
  }
}

.filter-header {
  display: flex;
  height: 18px;
  margin: 5px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.filter-header-text {
  color: var(--ap-white, #fff);
  font-family: Poppins;
  font-size: var(--custom-filter-header-text-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

react-toggle-track:hover {
  background-color: yellow;
}
