@use '../../../common/css/base' as base;

.tab-div {
  flex: 1;
  // border-left: 1px solid white !important;
  // border-right: 2px solid white !important;
}

.drop-target-highlight .tab {
  background-color: #404040;
  color: #dcdcdc;
}

.tab-div:hover {
  background-color: #b8b8b8 !important;
}

img {
  user-select: none;
}

.tab-container-common {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  height: var(--custom-tab-height) !important;
  gap: var(--custom-tab-gap) !important;
  width: -webkit-fill-available;
  position: relative;
  background-color: base.$ap-header-gray;
  border: 0.147px solid transparent;
  border-radius: 0px 0px 5px 5px;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
}

// .tab-container-common::-webkit-scrollbar {
//   display: none;
// }

.start-tab-common {
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: right;
  // border-radius: 0px 0px 0px 5px;
  width: calc(var(--custom-start-tab-width)) !important;
  height: var(--custom-tab-height) !important;
  // gap: 25% !important;
  flex: 0 0 auto;
}

.tab-holder-inner {
  display: flex;
  max-width: calc(100% - 100px);
  overflow-x: scroll;
  flex: 0 1 auto;
  gap: var(--custom-tab-gap) !important;
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
}
.tab-holder-outer {
  display: flex;
  overflow: hidden;
}

.tab-tools-holder {
  // position: absolute;
  display: flex;
  // right: 0;
}

.filling-tab {
  background: #dcdcdc;
  display: flex;
  flex: 1;
  // border-radius: 0px 0px 5px 0px;
}

.navigator-tab {
  // @extend .tab-container-common;
  z-index: 9999;
  width: 150px !important;
  display: flex;
  gap: 10px !important;
  justify-content: center;
  align-items: center;
  width: var(--custom-nav-width) !important;
  background-color: base.$ap-light-gray !important;
  border-color: white !important;
  border-radius: 0px 0px 5px 0px;
  background: linear-gradient(
    to right,
    base.$ap-light-gray,
    transparent
  ); /* Create a gradient background */
  // box-shadow: 0px 0 5px rgba(0, 0, 0, 0.7);
  padding-left: 4px;
}

.tab {
  cursor: pointer;
  background: #dcdcdc;
  width: var(--custom-tab-width) !important;
  height: var(--custom-tab-height) !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: var(--custom-font-size);
  line-height: 1rem;
  padding: 0rem 0.625rem;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* AP black */

  color: #404040;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.inbox-tab-filter {
  width: var(--custom-inbox-tab-width) !important;
}