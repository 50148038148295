@use "../../../common/css/base" as base;

.filter-text {
  display: flex;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: base.$ap-white;
  text-align: right;
  flex-direction: column;
  justify-content: center;
}

#upload-grid {
  * > .ag-root-wrapper {
    border-radius: 0px;
  }
  * > .ag-header {
    height: calc(
      var(--custom-page-header-height) +
        var(--custom-page-header-border-element-height)
    ) !important;

    min-height: calc(
      var(--custom-page-header-height) +
        var(--custom-page-header-border-element-height)
    ) !important;
  }
  * > .ag-header {
    border-bottom: 0px !important;
  }
  * > .ag-header-viewport {
    background-color: base.$ap-header-gray;
    flex: none !important;
  }
  * > .ag-header-container {
    background: linear-gradient(to right, base.$ap-header-gray 99.3%, #4e4d4d 0.65%) !important;
  }
  * > .ag-body-vertical-scroll {
    background-color: base.$ap-dialog-black;
  }
  * > .ag-header-cell-comp-wrapper {
    border-radius: 0px;
    padding: 15px;
  }
  * > .ag-header-cell {
    flex-direction: column;
  }
  * > .ag-header-cell:last-child {
    background: linear-gradient(to right, base.$ap-header-gray 100%, #4e4d4d 0) !important;
    border-right: 0px !important;
  }
  * > .ag-cell {
    padding: var(--custom-upload-dialog-cell-padding);
    font-size: var(--custom-font-size);
  }
  * > .ag-body-vertical-scroll {
    --ag-background-color: #ffff55;
  }
  * > .ag-header-row {
    position: initial !important;
  }
}

.close-btn-orange {
  background-color: #FFB000 !important;
  // margin-right: 1.25rem;
}

.close-btn-white {
  background-color: white !important;
  // margin-right: 1.25rem;
}

.close-button {
  display: flex;
  width: var(--custom-close-width);
  height: var(--custom-close-height);
  padding: var(--custom-close-padding);
  justify-content: center;
  align-items: center;
  gap: var(--custom-close-gap);
  flex-shrink: 0;
  border: 1px solid var(--custom-close-border-radius);
  border-radius: var(--custom-close-border-radius);
  background: #fff;
  outline: none;
}

.close-button-text {
  color: base.$ap-close-button-black;
  text-align: center;
  font-family: base.$font;
  font-size: var(--custom-close-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.upload-filter-text {
  height: var(--custom-upload-dialog-header-height);
}

.upload-logo {
  width: var(--custom-upload-logo-width);
  height: var(--custom-upload-logo-height);
  // margin-right: 1.25rem;
}

@media screen {
  #upload-grid {
    * > .ag-header-container {
      background: linear-gradient(to right, base.$ap-header-gray 95.3%, #4e4d4d 0.65%) !important;
    }
  }
}

/* CSS for Safari */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    #upload-grid {
      * > .ag-header-container {
        background: linear-gradient(to right, base.$ap-header-gray 93.5%, #4e4d4d 0.65%) !important;
      }
    }
  }
}
.upload-file-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file-upload-warning {
  color: red;
  font-weight: 700;
  font-size: 0.9rem;
}