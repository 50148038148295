.tf-tenant-entity {
  margin-left: 0.25rem;
  display: flex;
  gap: 0.5rem;
  .tfb-content-top-nav span {
    color: #ffb000;
    padding-left: 8px;
  }
  .tfte-group-input {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0.5rem;
    .tfte-input {
      width: 80%;
      color: var(--AP-dark-gray, #4b4b4b);
      font-family: Poppins;
      font-weight: 400;
      padding: 2px 5px !important;
      height: 21px;
      outline: none;
    }
    .tfte-input-save {
      display: flex;
      margin-left: 0.7rem;
      width: 32px;
      height: 21px;
      padding: 3.5px 12.615px;
      justify-content: center;
      align-items: center;
      gap: 11.743px;
      flex-shrink: 0;
      cursor: pointer;
      border-radius: 3.523px;
      background: var(--AP-medium-darker-gray, #ffb000);
      color: #4b4b4b;
      font-weight: 600;
    }

    .tfte-save-disabled {
      background: var(--AP-medium-darker-gray, #585858);
      color: #404040;
      pointer-events: none;
    }
    .tfte-label {
      width: 4.25rem;
      word-wrap: break-word;
    }
  }
  .tfte-group-exact-phrases {
    width: 50%;
    .tfte-content-holder {
      background-color: #585858;
    }
  }
  .tfte-add-btn-container {
    width: 10%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    .tfte-add-btn {
      border-radius: 3.523px;
      background-color: #ffb000;
      color: #4b4b4b;
      font-weight: 600;
      padding: 5px 12px;
      display: flex;
      gap: 3px;
      cursor: pointer;
      &:hover {
        transform: scale(1.09);
      }
    }
    .tfte-add-btn-disabled {
      background: var(--AP-medium-darker-gray, #585858);
      pointer-events: none;
    }
  }
  .tfte-group-history {
    width: 40%;
    .tfte-label {
      margin-top: 0.5rem;
      color: #ffb000;
      padding-left: 8px;
    }
  }
  .tfte-group {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    gap: 0.25rem;
    // justify-content: space-between;
    .tfte-label {
      // width: 4.25rem;
      word-wrap: break-word;
      font-weight: 600;
    }

    .tfte-content-holder {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding-top: 0.5rem;
      .tfte-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2px 6px;
        margin-right: 0.25rem;
        margin-bottom: 0.15rem;
        cursor: pointer;
        &.tfte-text-selected {
          background: var(--AP-white, #fff);
          .tfte-text {
            color: var(--AP-black-gray, #404040);
          }
        }
        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
        .tfte-text {
          width: 15rem;
          white-space: normal;
          word-wrap: break-word; 
          word-break: break-word;
          overflow-wrap: break-word;
          padding: 1px 2px;
         
        }
        .tfte-text-tools {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          visibility: hidden;
        }
        .tfte-text-tools img:hover {
          transform: scale(1.15);
        }
        &:hover .tfte-text-tools {
          visibility: visible;
        }
        &:hover {
          // border-bottom: 1px solid gray;
        }
      }
    }
  }
}

.tfn-dummy-nav {
  width: 75px;
  margin-top: 1rem;
}
.tfbc-top-nav-content {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.tfte-history-loading {
  // justify-content: center;
  display: flex;
  font-style: italic;
  color: #ddd;
  padding-left: 0.5rem;
}

.tfte-input {
  color: var(--AP-dark-gray, #4b4b4b);
  font-family: Poppins;
  font-weight: 400;
  outline: none;
}
