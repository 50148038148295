@use '../../../../common/css/base' as base;

.market-header {
  flex-direction: column;
  height: 2.5rem;
  display: flex;
}

.settings-content-header-text-holder {
  display: flex;
  width: 100%;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
}

.market-grid {
  // columns: 170px 3;
  padding-right: 1rem;
  overflow-y: auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  column-fill: auto;
}

#new-market {
  padding-top: 1.75rem;
  height: 6rem;
  display: flex;
  flex-direction: column;
  margin-left: 0.1rem;

  [aria-label='display component'] {
    color: base.$ap-white;
    font-family: base.$font;
    font-size: var(--custom-settings-font-size);
    font-style: normal;
    font-weight: 600;
    line-height: var(--custom-settings-line-height);
    display: flex;
    align-items: center;
  }

  [aria-label='input component'] {
    color: base.$ap-black;
    font-family: base.$font;
    font-size: var(--custom-settings-font-size);
    font-style: normal;
    font-weight: 600;
    line-height: 16.44px;
    display: flex;
    align-items: center;
    width: 140px;
    height: 25px;
    border: 0.25px solid var(--ap-black, #404040);
    background: var(--ap-light-grey, #f2f2f2);
  }

  .market-creator-header-text {
    color: base.$ap-white;
    font-size: var(--custom-market-input-font-size);
    font-weight: 600;
    line-height: var(--custom-settings-line-height);
    padding-bottom: 0.25rem;
  }

  .market-creator-header-subtitle {
    font-size: var(--custom-market-creator-header-subtitle);
    font-weight: 400;
  }
}

.market-tools {
  margin-left: 0.5rem;
  visibility: hidden;
}

.market-item:hover {
  .market-tools {
    visibility: visible;
  }
}

.market-container {
  display: flex;
  width: 197px;
  text-wrap: wrap;
  word-break: break-word;
  margin: 0.2rem 0;
  padding:6px 8px;
  align-items: flex-start;
  justify-content: space-between;
}

.market-item {
  display: flex;
  cursor: pointer;
  color: wheat;
  &:hover {
    background-color: #404040;
  }
}

.market-display-name {
  color: base.$ap-white;
  font-family: base.$font;
  font-size: var(--custom-market-input-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: var(--custom-settings-line-height);
}

.market-input-box {
  width: 195px;
  height: 20px;
  border: 0;
  outline: none;
}

.market-input-name {
  display: flex;
  outline: none;
  width: 153px;
  height: 100%;
  color: #404040;
  font-family: Poppins;
  font-size: var(--custom-market-input-font-size);
}
.market-edit-enabled:hover {
  background-color: transparent !important;
}
.market-edit-enabled .market-container {
  padding-left: 0;
  padding-right: 16px;
}
.market-edit-enabled .market-input-group{
  background-color: white !important;
}

.market-edit-enabled .market-input-name {
  border: 0;
  outline: none;
}

.market-edit-enabled .market-tools {
  visibility: visible;
  margin-right: 0.5rem;
}

.market-input-group {
  display: flex;
}

.market-input-name::selection {
  background-color: #404040; /* Change background color of selected text */
  color: white; /* Change font color of selected text */
}

.ms-btn-container {
  display: flex;
  justify-content: flex-end;
}
