@use '../../../../common/css/base' as base;

.selected-tab {
  font-weight: 600 !important;
  background: var(--AP-medium-yellow, #FFB000);
}

.tab-name-unread-count {
  display: flex;
  gap: 5px;
  align-items: center;
}

.tab-name {
  display: flex;
  align-items: center;
}

.tab-name:first-child div {
  max-width: 70%;
}

.unread-count {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.85rem;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unread-count-selected {
  background-color: #e99210;
}

.unread-count-unselected {
  background-color: #777;
}

.tab-tooltip {
  font-family: base.$font;
  font-size: var(--custom-font-size);
}

.tab-editable-value {
  color: base.$ap-black;
  height: var(--custom-tab-height) !important;
  display: flex;
  align-items: center;
  border-right: 3px solid white;
  margin-left: -2px;
  input {
    font-family: Poppins;
    outline: none;
    border: none;
    font-size: var(--custom-font-size);
  }
}

.selected-tab-filter {
  background: var(--AP-black-gray, #404040);
  color: #ffb000;
  font-weight: 600;
}

.add-tab {
  color: var(--AP-black-gray, #404040);
  font-family: Poppins;
  font-weight: 600;
  font-size: var(--custom-font-size);
  width: 4rem;
  display: flex;
  gap: 0.25rem;
  padding-left: 0.25rem;
  background: var(--AP-light-gray, #F2F2F2);
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.add-tab:hover {
  transform: scale(1.05);
}

.tab-pointer:hover {
  transform: scale(1.25);
}

.tf-filter-name-input {
  color: #ffb000;
  font-weight: bolder;
  background: none;
  cursor:text;
  border: none;
  // border-bottom: 2px solid #e99210;
  // min-width: 90px;
  font-family: Poppins;
  font-weight: 600;
}

.tf-filter-name-input:focus {
  outline: none;
}

.tab-share-btn {
  width: 1rem;
  height: auto;
  // width: 1.5rem;
  // height: 1.5rem;
  margin: 0 0.25rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.15);
  }
}


