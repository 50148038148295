@use "../../../common/css/base" as base;

.settings-holder {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 0px;
  padding: 18px;
  box-sizing: border-box;
}

.setting-tab {
  display: flex;
  width: auto;
  height: 38px;
  margin-right: 1rem;
  padding: 0px 10px 0px 10px;
  align-items: center;
  flex-shrink: 0;
}

.setting-tab-text-selected {
  color: base.$ap-black;
  background-color: base.$ap-white;
}

.setting-tab-text-not-selected {
  color: base.$ap-white;
}

.setting-tab-text {
  font-family: base.$font;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  line-height: var(--custom-settings-line-height);
}

.settings-tab-content-container {
  flex-direction: row;
  box-sizing: border-box;
  padding: 20px 0px 20px 0px;
  height: 100%;
  width: 100%;
}

.settings-tab-container {
  width: 165px;
  height: calc(100% - 2rem);
  flex-direction: column;
  border-right: 0.5px solid white;
}

.settings-content-container {
  margin: 0px 20px 0px 20px;
  height: 100%;
  width: calc(100% - 215px);
  padding-bottom: 5px;
  overflow: hidden;
  box-sizing: border-box;
}

.settings-content-header-text {
  color: base.$ap-yellow;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.settings-content-sub-header-text {
  color: base.$ap-white;
  font-size: 0.65rem;
  font-weight: 400;
  line-height: 16.44px;
}

.settings-button-container {
  gap: 20px;
}

.settings-button {
  display: flex;
  width: auto;
  height: var(--custom-close-height);
  padding: 5.872px 17.615px;
  justify-content: center;
  align-items: center;
  gap: 11.743px;
  flex-shrink: 0;
  border-radius: 3.523px;
  font-family: Poppins;
}

.settings-button-text {
  color: base.$ap-black;
  text-align: center;
  font-size: var(--custom-close-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.market-add-tools {
  margin-left: 0.5rem;
}

.market-item-display-holder {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input-group {
  position: relative;
  padding-top: 10px;
}

.custom-input {
  padding-right: 50px; /* Adjust padding for icons */
}

.icons {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.cancel-icon,
.save-icon {
  cursor: pointer;
  margin-left: 5px;
}

.cancel-icon:hover,
.save-icon:hover {
  transform: scale(1.15); /* Optionally change color on hover */
}