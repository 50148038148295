/* Modal */
.modal-overlay {
  //   position: fixed;
  //   inset: 0;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 8px;
  * {
    font-family: Poppins !important;
  }

  .modal-container {
    background: white;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 300px;
    overflow: hidden;

    svg {
      width: 15px;
    }

    .modal-header-content {
      padding: 8px;
      border-bottom: 1px solid #e5e7eb;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .profile-section {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        .user-details-holder {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .profile-icon-large {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }

        .user-name {
          font-size: 12.5px;
          font-weight: 600;
          color: #1f2937;
        }

        .user-email {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 10px;
          color: #6b7280;
        }

        .user-role {
          font-size: 10px;
          font-weight: 500;
        }
      }

      .modal-close-button {
        background: none;
        border: none;
        cursor: pointer;
        color: #9ca3af;
        transition: color 0.2s;
      }

      .modal-close-button:hover {
        color: #4b5563;
      }
    }

    .modal-body {
      padding: 6px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .menu-item {
        width: 100%;
        text-align: left;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        transition: background 0.2s;
        background-color: transparent;
      }

      .menu-item:hover {
        background-color: #f3f4f6;
      }

      .menu-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .menu-label {
        font-size: 12.5px;
        font-weight: 500;
      }
    }
  }
}

/* Background Colors */
.blue-bg {
  background-color: #031cff;
}
.blue-text {
  color: #031cff;
}
.blue-bg-light {
  background-color: #e6f0fd;
}
.green-bg {
  background-color: #2ba700;
}
.green-text {
  color: #2ba700;
}
.green-bg-light {
    background-color: #eafaef;
}
yellow-bg {
  background-color: #facc15;
}
purple-bg {
  background-color: #8b5cf6;
}
.gray-hover:hover {
  background-color: #e5e7eb;
}
.red-hover:hover {
  background-color: #fee2e2;
}
.red-text {
  color: #dc2626;
}
