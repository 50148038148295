@use "../../common/css/base" as base;

.content-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tab-filter-settings {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  // left: calc(63% - 576px);
  width: 760px;
  height: 423px;
  z-index: 10;
}

.tab-filter-delete-warning {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  // left: calc(63% - 576px);
  width: 286px;
  height: 130px;
  z-index: 10;
}

.tab-filter-rename {
  position: absolute;
  top: 35%;
  left: 42%;
}

.custom-context-menu {
  max-height: 240px !important; 
  overflow-y: auto;
}