.dtw-container {
  background: var(--AP-black-gray, #404040);
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
}
.dtw-warning-text {
  color: white;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.5rem;
}
.dtw-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1.15rem;
  .dtw-button-yellow {
    background-color: #ffb000;
  }
  .dtw-button {
    width: auto;
    font-size: 0.75rem;
    height: 30px;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 3.523px;
    font-family: Poppins;
    font-weight: 600;
  }
}
